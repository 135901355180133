






























































































































import { app as microsoftTeams } from "@microsoft/teams-js"

import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  PropType,
} from "@vue/composition-api"
import WithHeaderLayout from "@/layouts/WithHeaderLayout.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import PickDateTime from "@/components/pickDateTime/PickDateTime.vue"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import RichEditor from "@/components/textAreas/RichEditor.vue"
import User from "@/models/User"
import { notificationContainer } from "@/containers/NotificationContainer"
import { alertContainer } from "@/containers/AlertContainer"
import { TableItem } from "./PersonalNotification.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import DateTime from "@/models/DateTime"
import { i18nContainer } from "@/containers/I18nContainer"
import Icon from "@/components/Icon.vue"
import UsersAutoComplete from "@/views/UsersAutoComplete.vue"

interface State {
  isLoadingUpdate: boolean
  members: Array<User>
  users: Array<User>
  body: object | null
  bodyHtml: string
  item: TableItem
  notificationDate: DateTime
  isFormValid: boolean
}

export default defineComponent({
  props: {
    tableItem: {
      type: Object as PropType<TableItem>,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    body: {
      type: Object,
    },
  },
  components: {
    RichEditor,
    PickDateTime,
    I18nFormattedMessage,
    WithHeaderLayout,
    DefaultDialog,
    Icon,
    UsersAutoComplete,
  },
  setup(props, { emit }) {
    const { getMembers } = teamsContextContainer.useContainer()
    const { showSuccessMessage } = alertContainer.useContainer()
    const {
      createNotification,
      deleteNotification,
    } = notificationContainer.useContainer()
    const { formatMessage } = i18nContainer.useContainer()

    const state = reactive<State>({
      isLoadingUpdate: false,
      members: [],
      users: [],
      body: null,
      bodyHtml: "",
      item: {} as TableItem,
      //日付エラー表示を避けるため、日付初期値を現在日の翌日に設定
      notificationDate: new DateTime().add({ days: 1 }),
      isFormValid: false,
    })

    const setItemValues = async (newValue: TableItem) => {
      if (!newValue.groupId || !newValue.channelId) return
      state.members = (
        await getMembers(newValue.groupId, newValue.channelId)
      ).map(m => new User(m))
      state.users = state.members.filter(m =>
        newValue.userIds.some(u => u === m.userId)
      )
      state.notificationDate = new DateTime(
        new Date(state.item.notificationDate)
      )
    }

    watch(
      () => props.tableItem,
      async newValue => {
        state.item = Object.assign({}, newValue)
        await setItemValues(newValue)
      }
    )

    watch(
      () => props.visible,
      visible => {
        if (!visible) {
          state.members = []
          state.users = []
        }
      }
    )

    const onOk = async () => {
      state.isLoadingUpdate = true
      const target = document.querySelector(
        "#notification-message-target .ProseMirror"
      ) as HTMLElement
      if (target) {
        state.item.message = state.bodyHtml
        state.item.userIds = state.users.map(u => u.userId || "")
        try {
          const [item] = await Promise.all([
            createNotification(
              state.item.meetingId,
              state.item.siteId,
              {
                channelId: state.item.channelId,
                teamId: state.item.teamId,
                isPrivate: state.item.isPrivate,
              },
              target,
              state.notificationDate,
              state.users
            ),
            deleteNotification(
              state.item.siteId,
              state.item.id,
              state.item.driveItems
            ),
          ])
          showSuccessMessage("通知を更新しました")
          state.item.notificationDate = state.notificationDate.toDateStringSlashFull()
          emit("ok", {
            item: state.item,
            newItemId: item.notification.id,
            driveItems: item.driveItems,
          })
        } finally {
          state.isLoadingUpdate = false
        }
      }
    }

    const disallowPast = [
      (value: DateTime) => {
        if (value.isBefore(new DateTime(new Date()), "minutes"))
          return formatMessage({
            id: "past",
            defaultMessage: "過去日時を設定できません",
          })
        return true
      },
    ]

    const onCancel = () => {
      emit("cancel")
    }

    const changeBody = (currentHtml: string) => {
      state.bodyHtml = currentHtml
    }
    const goto = (url: string) => {
      microsoftTeams.openLink(url)
    }

    onMounted(async () => {
      state.item = Object.assign({}, props.tableItem)
      await setItemValues(state.item)
    })

    return {
      state,
      onOk,
      goto,
      onCancel,
      changeBody,
      disallowPast,
    }
  },
})
